import { Theme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"

import { resolveMediaUrl } from "@/helpers/api-helpers"
import { UploadFileEntityResponse } from "@/types/generated/strapi/graphql"

export const resolveDarkModeAsset = (
  theme: Theme,
  src: UploadFileEntityResponse,
  srcDark?: UploadFileEntityResponse | null,
) => {
  if (srcDark?.data && isEqual(theme.palette.mode, "dark"))
    return resolveMediaUrl(srcDark?.data?.attributes?.url)
  return resolveMediaUrl(src?.data?.attributes?.url)
}
