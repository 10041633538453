"use client"
import { useTheme } from "@mui/material/styles"
import map from "lodash-es/map"
import dynamic from "next/dynamic"
import { FC, memo, useCallback, useMemo } from "react"
import type { Options } from "react-markdown"

import remarkBreaks from "remark-breaks"
import remarkGfm from "remark-gfm"

import styles from "./RichText.module.scss"

import { AnimatedText } from "@/components/common/animatedText"
import type { AnimatedTextProps } from "@/components/common/animatedText"
import { Headline } from "@/components/common/headline"
import { Icon } from "@/components/common/icon"
import { Link } from "@/components/common/link"

import { create } from "@/helpers/bem"
import type { Schema } from "@/types/schema"
import type { Variants } from "@/types/variants"

const Markdown = dynamic(
  () =>
    import("react-markdown").then((module) => ({ default: module?.default })),
  { ssr: false },
)

const bem = create(styles, "RichText")

export type RichTextProps = Options & {
  animated?: boolean
  schema?: Schema
  animationProps?: Omit<AnimatedTextProps, "children">
  variant?: Variants
  itemProp?: string
}

export const RichText: FC<RichTextProps> = memo(
  ({
    className,
    animated,
    animationProps = {},
    variant = "primary",
    schema = {},
    itemProp,
    children,
    ...props
  }) => {
    const theme = useTheme()
    const palette = theme.palette
    const themeModifier = {
      [palette.mode]: true,
    }
    const baseProps = useMemo(
      () => ({
        animated,
        className: className ?? undefined,
        ...schema,
      }),
      [animated, schema, className],
    )
    const headlineProps = useMemo(
      () => ({
        animationProps,
      }),
      [animationProps],
    )
    const renderAnimation = useCallback(
      (children: any) => {
        if (!animated) return children
        return <AnimatedText {...animationProps}>{children}</AnimatedText>
      },
      [animated, animationProps],
    )
    return (
      <Markdown
        remarkPlugins={[remarkGfm, remarkBreaks]}
        components={{
          h1: (props) => (
            <Headline {...baseProps} {...headlineProps} size="xxl" type="h1">
              {props?.children}
            </Headline>
          ),
          h2: (props) => (
            <Headline {...baseProps} {...headlineProps} size="xl" type="h2">
              {props?.children}
            </Headline>
          ),
          h3: (props) => (
            <Headline {...baseProps} {...headlineProps} size="lg" type="h3">
              {props?.children}
            </Headline>
          ),
          h4: (props) => (
            <Headline {...baseProps} {...headlineProps} size="md" type="h4">
              {props?.children}
            </Headline>
          ),
          h5: (props) => (
            <Headline {...baseProps} {...headlineProps} size="sm" type="h5">
              {props?.children}
            </Headline>
          ),
          h6: (props) => (
            <Headline {...baseProps} {...headlineProps} size="xs" type="h6">
              {props?.children}
            </Headline>
          ),
          p: (props) => (
            <p
              {...baseProps}
              className={bem("p", themeModifier, className ?? undefined)}
              itemProp={itemProp}
            >
              {renderAnimation(props?.children)}
            </p>
          ),
          a: (props) => (
            <Link
              {...baseProps}
              aria-label={props?.children?.toString()}
              href={props?.href ?? "#"}
            >
              {renderAnimation(props?.children)}
            </Link>
          ),
          pre: (props) => (
            <pre
              {...baseProps}
              className={bem("pre", themeModifier, className ?? undefined)}
            >
              {renderAnimation(props?.children)}
            </pre>
          ),
          ul: (props) => (
            <ul
              {...baseProps}
              className={bem("ul", themeModifier, className ?? undefined)}
            >
              {renderAnimation(props?.children)}
            </ul>
          ),
          ol: ({ node }) => (
            <ol
              {...baseProps}
              className={bem("ol", themeModifier, className ?? undefined)}
            >
              {map(
                node?.children.filter((el) => el?.type !== "text"),
                (
                  e: {
                    value: string
                    children: object
                  },
                  index: number,
                ) =>
                  e?.children?.[0]?.value ? (
                    <li
                      {...baseProps}
                      className={bem(
                        "li",
                        themeModifier,
                        className ?? undefined,
                      )}
                    >
                      <i className={bem("ol__decimal", themeModifier)}>
                        {index + 1}
                      </i>
                      {renderAnimation(<span>{e.children[0].value}</span>)}
                    </li>
                  ) : null,
              )}
            </ol>
          ),
          li: (props) => (
            <li
              {...baseProps}
              className={bem("li", themeModifier, className ?? undefined)}
            >
              <Icon
                className={bem("li__icon")}
                color={palette?.[variant]?.main}
                name="ArrowRightCircle"
                size={18}
              />
              {renderAnimation(<span>{props?.children}</span>)}
            </li>
          ),
        }}
        {...props}
      >
        {children}
      </Markdown>
    )
  },
)

RichText.displayName = "RichText"
